const errorMessages = {
  genericError: {
    title: 'Something went wrong',
    message: 'An error has occurred. Try relaunching the tool from Canvas or contacting support.'
  },
  genericNotFound: {
    title: 'Resource could not be found',
    message:
      'Requested resource missing. Try relaunching the tool from Canvas or contacting support.'
  },
  unauthorisedAccess: {
    title: "You don't have access to this tool",
    message:
      "You don't have access to view this page, or your current session has expired. Relaunch the tool from Canvas."
  },
  noSessionId: {
    title: 'Currently Offline',
    message:
      'Please use the button below to login to the Engagement Tool.'
  },
  decodeSessionIdError: {
    title: 'Something went wrong',
    message: 'Something went wrong while obtaining course/user information from canvas.'
  },
  insufficientPrivileges: {
    title: "You don't have access to this page",
    message:
      'You do not have permission to access the engagement tool. Try launching from Canvas or contact support for help.'
  },
  noTeachingPeriods: {
    title: 'There is no data for this course',
    message:
      'There is no engagement data for this course to review. Please select a different course in Canvas.'
  },
  sessionExpired: {
    title: 'Your session has expired',
    message:
      'You will need to return to Canvas and click the link to the <strong>Engagement tool</strong> to start a new session.'
  },
  sessionExpiryWarning: {
    title: 'Your session ends soon',
    message:
      'Your session is about to expire soon. Please save any outstanding changes and start a new session from Canvas'
  },
  saveNoteError: {
    title: 'Your intervention event has not been saved',
    message: 'Something went wrong while saving your the event. Please try again'
  },
  sendSmsError: {
    title: 'SMS could not be sent',
    message:
      'Something went wrong while attempting to send an SMS to the selected students. Please try again or contact support for assistance.'
  },
  signinFailError: (error) => {
    return {
      title: 'Failed to Login',
      message:
        `Could not login.<br/>Error: ${error}`
    };
  }
};

export default errorMessages;
