import create from 'zustand';
import { devtools } from 'zustand/middleware';
import produce from 'immer';
import shallow from 'zustand/shallow';

import { handleApiRequest, constructApiTemplate } from 'utils/apiUtils';
import { errorMessages } from '../app/constants/errorMessages';
import { getApiRoot, getApiKey } from 'utils/environmentUtils';
import { getAccessToken } from 'utils/authUtils';
import { createAlgoliaStore } from './algolia';
import { createAuthStore } from './auth';
import { createDrawerStore } from './drawer';
import { createModalStore } from './modal';
import { createSearchStore } from './search';
import { createSessionStore } from './session';
import { createSmsStore } from './sms';
import { createSmsTemplatesStore } from './smsTemplates';
import { createStudentStore } from './student';
import { createStudentsStore } from './students';
import { createStudentsPIIStore } from './studentsPII';
import { createTeachingPeriodsStore } from './teachingPeriods';
import { createTwilioStore } from './twilio';
import { transformApiError } from 'utils/transformApiResponseUtils';

const Store = (set, get) => ({
  error: null,

  algoliaStore: createAlgoliaStore(set, get),
  authStore: createAuthStore(set, get),
  sessionStore: createSessionStore(set, get),
  drawerStore: createDrawerStore(set),
  modalStore: createModalStore(set),
  searchStore: createSearchStore(set, get),
  smsStore: createSmsStore(set, get),
  smsTemplatesStore: createSmsTemplatesStore(set, get),
  studentStore: createStudentStore(set, get),
  studentsStore: createStudentsStore(set, get),
  studentsPIIStore: createStudentsPIIStore(set, get),
  teachingPeriodsStore: createTeachingPeriodsStore(set, get),
  twilioStore: createTwilioStore(set, get),

  actions: {
    handleError(error) {
      set((state) => {
        state.error = error;
      });
    },

    async apiRequest(request) {
      const { params, data, method, url } = constructApiTemplate(request);
      const headers = { ...request.headers };
      let accessToken = null;

      try {
        accessToken = await getAccessToken();
      } catch (err) {
        set((state) => {
          state.error = errorMessages.sessionExpired;
          state.authStore.isAuthenticated = false;
          state.authStore.hasSessionExpired = true;
        });
      }

      if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
        headers['X-Api-Key'] = getApiKey();
      }

      const constructedRequest = {
        url: getApiRoot() + url,
        method,
        ...(headers && { headers }),
        ...(params && { params }),
        ...(data && { data })
      };

      return handleApiRequest(constructedRequest);
    },

    handleApiError(status) {
      const error = transformApiError(status);
      set((state) => {
        state.error = error;
      });
    }
  }
});

// As there's currently no benefit of persisting data, lets turn it off
// until auth is completely moved to zustand
// const useStore = create(
//   persist(devtools(Store), {
//     name: 'interventions-app-storage'
//   })
// );

// Immer middleware
const immer = (config) => (set, get, api) => config((fn) => set(produce(fn)), get, api);

const useStore = create(devtools(immer(Store)));

export { useStore, shallow };
