import { apiEndpoints } from 'app/constants/apiEndpoints';
import { getErrorMessage } from '../utils/apiUtils';
import { errorMessages } from '../app/constants/errorMessages';
import { SMS_WORKFLOW_PHONE_CALL } from '../app/constants/workflows';
import { getDetailsMapped } from '../utils/detailsUtils';
import { createStatusFromTerm } from '../utils/statusListUtil';

const createSessionStore = (set, get) => ({
  isFetchingSessionData: false,
  courseName: null,
  courseKey: null,
  unitId: null,
  filterDetails: null,
  periods: null,
  staff: null,
  workflow: null,
  outcomes: null,
  referredServices: null,

  fetchSessionData: async () => {
    set((state) => {
      state.sessionStore.isFetchingSessionData = true;
    });

    const { apiRequest } = get().actions;
    const response = await apiRequest(apiEndpoints.session);

    const outcomeList = createStatusFromTerm(response.data.terms, 'outcome');
    const referredServiceList = createStatusFromTerm(response.data.terms, 'referred-service');

    if (response.status === 404) {
      set((state) => {
        state.teachingPeriodsStore.isFetchingTeachingPeriods = false;
        if (get().algoliaStore.isKeyAvailable) {
          state.error = errorMessages.noTeachingPeriods;
        }
      });
      return;
    }

    if (response.status !== 200) {
      const error = getErrorMessage(response.status);
      set((state) => {
        state.teachingPeriodsStore.isFetchingTeachingPeriods = false;
        state.error = error;
      });
      return;
    }

    let currentTps = response.data.periods;

    const microTpItems = currentTps.filter((item) => item.microTp !== 0);

    if (microTpItems.length > 0) {
      currentTps = [...currentTps].sort((a, b) => b.microTp - a.microTp);
    }

    set((state) => {
      state.sessionStore.unitId = '';
      state.sessionStore.courseName = '';
      state.sessionStore.courseKey = null;
      state.sessionStore.periods = currentTps;
      state.sessionStore.staff = [];
      state.sessionStore.filterDetails = getDetailsMapped(response.data.terms);
      state.sessionStore.workflow = response.data.workflow ?? SMS_WORKFLOW_PHONE_CALL;
      state.sessionStore.outcomes = outcomeList;
      state.sessionStore.referredServices = referredServiceList;
    });

    get().algoliaStore.initSearchState();

    set((state) => {
      state.sessionStore.isFetchingSessionData = false;
    });

    await get().studentsPIIStore.fetchStudentsData();
  },

  getTeachingPeriodLabel(periodValue) {
    const { periods } = get().sessionStore;
    const period = periods.find((item) => item.periodId === periodValue);
    return `TP ${period.tp} - Week ${period.week}`;
  },

  getFilterDetails() {
    return get().sessionStore.filterDetails;
  }
});

export { createSessionStore };
