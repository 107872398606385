import camelCase from 'lodash.camelcase';
import keyBy from 'lodash.keyby';
import isEmpty from 'lodash.isempty';

const labelFormats = {
  prefix: (props) => `${props.value} ${props.label}`,
  postfix: (props) => `${props.label} ${props.value}`,
  sum: (props) =>
    `${props.value.length} ${props.value.length === 1 ? props.label_singular : props.label}`
};

const availableLabelFormats = Object.keys(labelFormats);

export const formatLabel = (props) => {
  if (props.format === 'sum' && typeof props.value !== 'object') return '';
  if (props.format === 'value') return props.value;

  return props.format.includes(availableLabelFormats)
    ? labelFormats[props.format](props)
    : props.label;
};

export const formatLabelUsingMap = (labelMap, key, value = null) => {
  try {
    return formatLabel(labelMap[key].label, labelMap[key].type, value);
  } catch (e) {
    return '';
  }
};

export const getLabelDictionary = (labelDictionary, tagValue) => {
  return !isEmpty(labelDictionary[tagValue])
    ? { icon: labelDictionary[tagValue].icon, text: labelDictionary[tagValue].label }
    : { icon: 'CONTINUING_STUDENT', text: tagValue };
};

export const filterTags = (tags) =>
  tags.filter((detail) => {
    if (detail.termLabel === 'Contact History') {
      return false;
    }

    if (typeof detail.tagValue === 'boolean') {
      return detail.tagValue;
    }

    if (typeof detail.tagValue === 'number' && detail.tagValue === 0) {
      return false;
    }

    return true;
  });

export const getTagsByKey = (tags) => {
  const camelCaseKeyTags = tags.map((tag) => ({
    ...tag,
    termKey: camelCase(tag.termKey)
  }));
  return keyBy(camelCaseKeyTags, 'termKey');
};

export const getDetailsMapped = (terms) => {
  return getTagsByKey(terms.filter((term) => term.termType === 'detail'));
};
