import initCognito from './initCognito';

export const getAccessToken = async () => {
  return new Promise((resolve, reject) => {
    const cognito = initCognito();
    cognito.userhandler = {
      onSuccess: (newSession) => {
        resolve(newSession.getIdToken().getJwtToken());
      },
      onFailure: (err) => {
        // Failed to refresh token, clear local cache
        cognito.clearCachedTokensScopes();
        reject(err);
      }
    };
    /**
     * Ideally a single `cognito.getSession()` should do this job.
     * However, when refreshing the token it cannot find `this.userhandler`
     * So we have to define the flow as following as a workaround.
     */
    const signInUserSession = cognito.getCachedSession();
    if (signInUserSession.isValid()) {
      cognito.userhandler.onSuccess(signInUserSession);
    } else if (
      signInUserSession.getRefreshToken() &&
      signInUserSession.getRefreshToken().getToken()
    ) {
      cognito.refreshSession(signInUserSession.getRefreshToken().getToken());
    } else {
      cognito.getSession();
    }
  });
};

export default { getAccessToken };
