import PropTypes from 'prop-types';
import React from 'react';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';

import LoadingSpinner from '../components/LoadingSpinner';
import PageLayout from '../components/PageLayout';
import { searchSettings } from '../app/constants/search';
import { useStore } from '../store';
import VirtualSearchWidgets from '../components/VirtualSearchWidgets';

const InstantSearchProvider = ({ children }) => {
  const {
    algoliaKey,
    isFetchingAlgoliaKey,
    indexName,
    requireRefetch,
    searchState,
    setSearchState
  } = useStore((state) => state.algoliaStore);

  const isAuthenticated = useStore((state) => state.authStore.isAuthenticated);
  const isFetchingSessionData = useStore((state) => state.sessionStore.isFetchingSessionData);
  const isFetchingStudentsData = useStore((state) => state.studentsPIIStore.isFetchingStudentsData);

  if (
    !isAuthenticated ||
    isFetchingSessionData ||
    isFetchingStudentsData ||
    (isFetchingAlgoliaKey && !algoliaKey)
  ) {
    return (
      <PageLayout>
        <LoadingSpinner />
      </PageLayout>
    );
  }

  const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: {
      apiKey: algoliaKey ? algoliaKey.value : '', // Be sure to use the search-only-api-key
      nodes: [
        {
          host: process.env.REACT_APP_TYPESENSE_HOST,
          port: process.env.REACT_APP_TYPESENSE_PORT,
          protocol: process.env.REACT_APP_TYPESENSE_PROTOCOL
        }
      ]
    },
    additionalSearchParameters: {
      queryBy: 'period_id,student_fullname'
    }
  });
  const onSearchStateChange = (updatedSearchState) => {
    if (updatedSearchState.refinementList) {
      setSearchState(updatedSearchState);
    }
  };

  return (
    <InstantSearch
      indexName={indexName}
      searchClient={typesenseInstantsearchAdapter.searchClient}
      searchState={searchState}
      stalledSearchDelay={100}
      refresh={requireRefetch}
      onSearchStateChange={onSearchStateChange}
    >
      <VirtualSearchWidgets />
      <Configure hitsPerPage={searchSettings.resultsPerPage} />
      {children}
    </InstantSearch>
  );
};

InstantSearchProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default InstantSearchProvider;
