import { linkUrls } from 'linkUrls';

const CANVAS_LOGIN_URL = process.env.REACT_APP_CANVAS_LOGIN_URL || linkUrls.login;

const CANVAS_SUPPORT_URL = process.env.REACT_APP_CANVAS_SUPPORT_URL || linkUrls.support;

const urlEncodedOrigin = encodeURIComponent(`${window.location.origin}/`);
const COGNITO_LOGIN_URL = `https://${process.env.REACT_APP_AWS_COGNITO_DOMAIN}/login?response_type=code&client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&scope=email+openid+profile&redirect_uri=${urlEncodedOrigin}`;

export default {
  CANVAS_LOGIN_URL,
  CANVAS_SUPPORT_URL,
  COGNITO_LOGIN_URL
};
