import { Helmet } from 'react-helmet';
import Hidden from '@material-ui/core/Hidden';
import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import logo from 'logo.svg';

import { useStore } from 'store';
import Grid from 'components/Grid';
import { StyledAppBar, StyledHeaderLogo, StyledHeaderTitle } from './Header.styles';

const Header = () => {
  const isDrawerOpen = useStore((state) => Boolean(state.drawerStore.activeDrawerType));
  const { getUserInfo } = useStore((state) => state.authStore);
  const { elaName } = getUserInfo();

  const pageSubtitle = elaName ? `Students Semester 2` : '';

  return (
    <StyledAppBar drawerOpen={isDrawerOpen}>
      <Helmet>
        <title>{`${process.env.REACT_APP_PAGE_TITLE}`}</title>
      </Helmet>
      <Toolbar>
        <Grid container spacing={3} alignItems="center">
          <Hidden xsDown>
            <Grid item>
              <StyledHeaderLogo src={logo} alt="logo" />
            </Grid>
          </Hidden>
          <Grid item>
            <StyledHeaderTitle>Engagement tool</StyledHeaderTitle>
            {pageSubtitle && (
              <Typography data-ref="course-info" variant={'body2'}>
                {pageSubtitle}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;
