import { decodeUserSession } from 'utils/decodeUserSessionUtils';
// import { SESSION_ID_KEY } from 'app/constants/session';
import { isJsonString } from 'utils/dataUtils';
import { errorMessages } from 'app/constants/errorMessages';

const createAuthStore = (set, get) => ({
  isAuthenticated: false,
  isAuthenticating: false,
  userSession: {},
  hasSessionExpired: false,

  setUserSession(sessionId) {
    try {
      const decodedSession = decodeUserSession(sessionId);
      // sessionStorage.setItem(SESSION_ID_KEY, sessionId);

      set((state) => {
        state.authStore.userSession = { sessionId, ...decodedSession };
      });

      // Only fetch algolia things when not using the mocked server
      if (process.env.REACT_APP_MOCK_SERVER !== 'true') {
        // trigger both algolia and unit fetch
        const { fetchAlgoliaKey } = get().algoliaStore;
        const { fetchSessionData } = get().sessionStore;
        fetchAlgoliaKey();
        fetchSessionData();
      }
    } catch (error) {
      const errorMessage = isJsonString(error.message)
        ? JSON.parse(error.message)
        : errorMessages.noSessionId;

      set((state) => {
        state.error = errorMessage;
      });
    }
  },

  sessionHasExpired() {
    set((state) => {
      state.authStore.hasSessionExpired = true;
    });
  },

  showSessionExpiryWarning() {
    set((state) => {
      state.error = errorMessages.sessionExpiryWarning;
    });
  },

  getUserInfo() {
    const { userEmail, username } = get().authStore.userSession;
    return {
      elaEmail: userEmail,
      elaName: username,
      fromPhoneNumber: process.env.REACT_APP_TWILIO_FROM_NUMBER
    };
  },

  signInSuccess() {
    set((state) => {
      state.authStore.isAuthenticated = true;
      state.authStore.isAuthenticating = false;
      state.authStore.hasSessionExpired = false;
    });
  },

  signInFailure(error) {
    set((state) => {
      state.authStore.isAuthenticated = false;
      state.authStore.isAuthenticating = false;
      state.authStore.hasSessionExpired = false;
      state.error = errorMessages.signinFailError(error);
    });
  }
});

export { createAuthStore };
