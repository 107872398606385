/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import isEmpty from 'lodash.isempty';
import { connectRefinementList } from 'react-instantsearch-dom';

import { ATTRIBUTE_KEY_CALL_STATUS_ID } from '../../app/constants/search';
import { callStatusLabels } from 'app/constants/callStatuses';
import { StyledAutocomplete } from './MultiSelectFilter.styles';
import { StyledCheckbox } from 'styles';
import { riskLevelIds } from 'app/constants/riskLevels';

// const capitaliseFirstChar = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const ConnectedMultiSelectFilter = connectRefinementList(
  ({ items, attribute: filterKey, refine, currentRefinement: filtersForKey, label }) => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
      setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
      setOpen(false);
    };

    // eslint-disable-next-line prefer-const
    let itemsOrdered = items;

    if (itemsOrdered.length > 0) {
      itemsOrdered.sort((a, b) => {
        const labelA = filterKey === 'risk_level_id' ? b.label : a.label.toUpperCase();
        const labelB = filterKey === 'risk_level_id' ? a.label : b.label.toUpperCase();

        if (labelA < labelB) {
          return -1;
        }

        if (labelA > labelB) {
          return 1;
        }

        return 0;
      });
    }

    const options = itemsOrdered.map((item) => item.label);

    const customLabels = (option) => {
      let customlabel = option;
      if (filterKey === ATTRIBUTE_KEY_CALL_STATUS_ID) {
        customlabel = callStatusLabels[option];
      } else if (filterKey === 'risk_level_id') {
        customlabel = riskLevelIds[option];
      }
      return customlabel;
    };

    return (
      <StyledAutocomplete
        multiple
        options={options}
        getOptionLabel={(option) => option}
        getOptionSelected={(option, value) => option === value}
        limitTags={0}
        open={open}
        value={filtersForKey}
        disableCloseOnSelect
        getLimitTagsText={(more) => `${more} selected`}
        className={!isEmpty(filtersForKey) ? 'has-value' : ''}
        id={`${filterKey}-input`}
        renderOption={(option, { selected }) => (
          <>
            <StyledCheckbox
              small
              checked={selected}
              data-ref={`multiselect-filter-checkbox-${option}`}
            />
            {customLabels(option)}
          </>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            placeholder="Show all"
            color="secondary"
            onClick={handleClick}
            data-ref={`multiselect-filter-text-field-${filterKey}`}
          />
        )}
        onChange={(evt, values) => {
          refine(values);
        }}
        PopperComponent={(props) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Popper {...props} style={{ width: 250 }} placement="bottom-start" />
          </ClickAwayListener>
        )}
      />
    );
  }
);

export default ConnectedMultiSelectFilter;

export { ConnectedMultiSelectFilter };
