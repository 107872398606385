export const authData = {
  AppWebDomain: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
  ClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
  RedirectUriSignIn: process.env.REACT_APP_AWS_COGNITO_REDIRECT_URI_SIGNIN,
  RedirectUriSignOut: process.env.REACT_APP_AWS_COGNITO_REDIRECT_URI_SIGNOUT,
  TokenScopesArray: ['email', 'openid', 'profile'],
  UserPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID
};

export const LOGIN_TYPE = 'agent';

export default { authData, LOGIN_TYPE };
