import { CognitoAuth } from 'amazon-cognito-auth-js';

import { authData } from '../app/constants/auth';
import { getSessionStorage } from './environmentUtils';

const getAuthData = () => ({
  ...authData,
  Storage: getSessionStorage()
});

export default () => {
  if (process.env.NODE_ENV === 'test') {
    return {
      getCachedSession: () => ({
        getIdToken: () => ({
          getJwtToken: () => 'test'
        }),
        isValid: () => true
      }),
      getCurrentUser: () => 'user',
      getSession: function getSession() {
        this.userhandler.onSuccess();
      }
    };
  }

  const auth = new CognitoAuth(getAuthData());
  auth.useCodeGrantFlow();
  return auth;
};
