import replace from 'lodash.replace';
import { useEffect } from 'react';
import URLSearchParams from '@ungap/url-search-params';
import { useLocation } from 'react-router-dom';

import { clearUriPath } from '../../utils/environmentUtils';
// import { errorMessages } from '../../app/constants/errorMessages';
import { getAccessToken } from '../../utils/authUtils';
import initCognito from '../../utils/initCognito';
import { useStore } from 'store';

const Authentication = () => {
  const { isAuthenticated, setUserSession, signInFailure, signInSuccess } = useStore(
    (state) => state.authStore
  );
  // const handleError = useStore((state) => state.actions.handleError);
  const { search } = useLocation();

  useEffect(() => {
    if (!isAuthenticated) {
      const urlParams = new URLSearchParams(search);
      if (urlParams.has('code') || urlParams.has('error_description')) {
        const cognito = initCognito();
        cognito.userhandler = {
          onSuccess: async (newSession) => {
            signInSuccess();
            clearUriPath();
            setUserSession(newSession.getIdToken().getJwtToken());
          },
          onFailure: async (error) => {
            await signInFailure(replace(decodeURIComponent(error), /\+/g, ' '));
            clearUriPath();
          }
        };
        const currentUrl = `${window.location.href}?${urlParams.toString()}`;

        cognito.parseCognitoWebResponse(currentUrl);
      } else {
        getAccessToken().then((accessToken) => {
          if (accessToken) {
            signInSuccess();
            setUserSession(accessToken);
          }
        });
      }
    }
  }, [signInSuccess, signInFailure, isAuthenticated, search]);

  /* TODO: Is this needed?
  if (!isAuthenticated) {
    handleError(errorMessages.noSessionId);
  }
  */
  return null;
};

export default Authentication;
