import { apiEndpoints } from 'app/constants/apiEndpoints';
import { CUSTOM_SMS } from 'app/constants/features';
import { getErrorMessage } from '../utils/apiUtils';
import { timeframes } from '../app/constants/smsTemplates';
import { transformSmsTemplates } from 'utils/transformApiResponseUtils';
import { settings } from '../app/settings';

const createSmsTemplatesStore = (set, get) => ({
  hasBeenFetched: false,
  isFetchingTemplates: false,
  templates: { STANDARD_TEMPLATE: { label: '', content: '' } },
  selectedTemplate: null,
  timeframes,

  fetchSmsTemplates: async () => {
    if (get().smsTemplatesStore.hasBeenFetched) {
      return;
    }

    set((state) => {
      state.smsTemplatesStore.isFetchingTemplates = true;
    });

    const { apiRequest } = get().actions;
    const { workflow } = get().sessionStore;

    const response = await apiRequest(apiEndpoints.smsTemplatesByWorkflow(workflow));

    const handleError = (error) => {
      set((state) => {
        state.smsTemplatesStore.isFetchingTemplates = false;
        state.smsTemplatesStore.hasBeenFetched = true;
        state.error = error;
      });
    };

    if (response.status !== 200) {
      const error = getErrorMessage(response.status);
      handleError(error);
      return;
    }

    if (!response.data || !response.data.length > 0) {
      const error = getErrorMessage({ status: 404 });
      handleError(error);
      return;
    }

    set((state) => {
      state.smsTemplatesStore.templates = {
        ...transformSmsTemplates(response.data),
        ...(settings[CUSTOM_SMS] && {
          CUSTOM_TEMPLATE: {
            label: 'Custom message',
            content: ''
          }
        })
      };
      state.smsTemplatesStore.hasBeenFetched = true;
      state.smsTemplatesStore.isFetchingTemplates = false;
      state.smsStore.template = response.data[0].key;
      state.smsStore.initialTemplate = response.data[0].key;
    });
  }
});

export { createSmsTemplatesStore };
