/* eslint-disable camelcase */
import isEmpty from 'lodash.isempty';

import { allowedUserRoles, SUPPORT_AGENT } from 'app/constants/userRoles';
import { decodeJwtToken } from './decodeJwtTokenUtils';
import { errorMessages } from 'app/constants/errorMessages';

export const decodeUserSession = (sessionId) => {
  const decodedUserSession = decodeJwtToken(sessionId);

  if (isEmpty(decodedUserSession)) {
    throw new Error(JSON.stringify(errorMessages.decodeSessionIdError));
  }

  const { sub, given_name, family_name, email, exp } = decodedUserSession;

  if (isEmpty(sub) || isEmpty(given_name) || isEmpty(family_name) || isEmpty(email)) {
    throw new Error(JSON.stringify(errorMessages.decodeSessionIdError));
  }

  // TODO: Currently for the pilot there are only Support Agents
  const userRoles = [SUPPORT_AGENT];

  if (!allowedUserRoles.some((role) => userRoles.includes(role))) {
    throw new Error(JSON.stringify(errorMessages.insufficientPrivileges));
  }

  const currentTime = Date.now();

  return {
    accessToken: sessionId,
    courseId: 1,
    expiresIn: exp,
    timeUntilSessionExpires: exp * 1000 - currentTime,
    userEmail: email,
    userLoginId: sub,
    username: `${given_name} ${family_name}`
  };
};
