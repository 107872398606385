import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import React from 'react';
import { createConnector } from 'react-instantsearch-dom';

import { ActiveFilters, SearchSelectionsWrapper } from './SearchSelections.styles';
import { callStatusLabels } from 'app/constants/callStatuses';
import Chip from 'components/Chip';
import SimpleButton from 'components/SimpleButton';
import { riskLevelIds } from '../../app/constants/riskLevels';
import { useStore } from 'store';

export const connectCurrentRefinements = createConnector({
  displayName: 'AlgoliaCurrentRefinements',

  propTypes: {
    transformItems: PropTypes.func
  },

  getProvidedProps(props, searchState, searchResults, metadata) {
    const filterMetadata = metadata.filter((item, i) => {
      return (
        metadata.findIndex((item2) => {
          return item.id === item2.id;
        }) === i
      );
    });

    const items = filterMetadata.reduce((res, meta) => {
      if (typeof meta.items !== 'undefined') {
        if (meta.id === 'multi') {
          const test = meta.items.reduce((cur, multiItem) => {
            const int = multiItem.items.map((item) => ({
              ...item,
              id: multiItem.id,
              index: meta.index
            }));
            return [...cur, ...int];
          }, []);
          return [...res, ...test];
        }

        if (!props.clearsQuery && meta.id === 'query') {
          return res;
        }

        if (props.clearsQuery && meta.id === 'query' && meta.items[0].currentRefinement === '') {
          return res;
        }

        return res.concat(
          meta.items.map((item) => ({
            ...item,
            id: meta.id,
            index: meta.index
          }))
        );
      }
      return res;
    }, []);

    const transformedItems = props.transformItems ? props.transformItems(items) : items;

    return {
      items: transformedItems,
      canRefine: transformedItems.length > 0
    };
  },

  refine(props, searchState, items) {
    const refinementsToClear = items instanceof Array ? items.map((item) => item.value) : [items];
    return refinementsToClear.reduce((res, clear) => clear(res), searchState);
  }
});

const SearchSelections = ({ items: unrefinedItems, refine }) => {
  const { filterLabels, activeFilters, setActiveFilters, removeFilter } = useStore(
    (state) => state.algoliaStore
  );
  const { getTeachingPeriodLabel } = useStore((state) => state.sessionStore);

  const items = unrefinedItems.filter((item) => item.attribute !== 'period.periodId');

  if (isEmpty(items) && isEmpty(activeFilters)) {
    return null;
  }

  const getLabel = (attribute, label) => {
    if (attribute === 'interventionStatusId') {
      return callStatusLabels[Number.parseInt(label, 10)];
    }
    if (filterLabels[attribute]) {
      return filterLabels[attribute].find((option) => option.originalLabel === label).label;
    }

    return label;
  };

  return (
    <SearchSelectionsWrapper>
      <ActiveFilters>
        {items.map((item) =>
          item.items.map(({ label, value }) => {
            let customLabel = getLabel(item.id, label);
            if (item.attribute === 'period_id') customLabel = getTeachingPeriodLabel(label);
            else if (item.attribute === 'risk_level_id') customLabel = riskLevelIds[label];
            return (
              <Chip
                key={customLabel}
                label={customLabel}
                onDelete={() => refine(value)}
                onClick={() => refine(value)}
              />
            );
          })
        )}
        {activeFilters.map(({ label, value }) => (
          <Chip
            key={value}
            label={label}
            onDelete={() => removeFilter(value)}
            onClick={() => removeFilter(value)}
          />
        ))}
        <SimpleButton
          onClick={() => {
            refine(items);
            setActiveFilters([]);
          }}
          label="Clear filters"
          data-ref="clear-active-filters"
          mr={1}
        />
      </ActiveFilters>
    </SearchSelectionsWrapper>
  );
};

SearchSelections.propTypes = {
  items: PropTypes.array,
  refine: PropTypes.func.isRequired
};

// export const ConnectedSearchSelections = connectCurrentRefinements(SearchSelections);

export default connectCurrentRefinements(SearchSelections);
