import { Device } from '@twilio/voice-sdk';
import lodashGet from 'lodash.get';

import { apiEndpoints } from 'app/constants/apiEndpoints';
import {
  cancel,
  connect,
  disabled,
  disconnect,
  offline,
  ringing,
  ready
} from 'app/constants/twilioConstants';
import { SMS_WORKFLOW_PHONE_CALL, workflowWithCallingEnabled } from '../app/constants/workflows';

const twilioState = {
  twilioDevice: null,
  twilioToken: null,
  activeConnection: null,
  callStatus: null,
  showDeviceOptions: false,
  hasInitialised: false
};

const isDev = process.env.NODE_ENV === 'development';

const createTwilioStore = (set, get) => ({
  ...twilioState,

  fetchTwilioDevice: async () => {
    const { apiRequest, handleApiError } = get().actions;
    const workflow = get().sessionStore.workflow ?? SMS_WORKFLOW_PHONE_CALL;
    const isEnabled = workflowWithCallingEnabled[workflow];

    if (!isEnabled) {
      set((state) => {
        state.twilioStore.twilioSession = {};
        state.twilioStore.callStatus = disabled;
      });
      return;
    }

    if (isDev) {
      set((state) => {
        state.twilioStore.twilioSession = {};
        state.twilioStore.callStatus = offline;
      });
      return;
    }

    const response = await apiRequest(apiEndpoints.twilioToken);

    if (response.status !== 200 || !response.data.token) {
      const status = lodashGet(response, 'data.message') === 'Unauthorized' ? 401 : 200;
      handleApiError({ status });

      set((state) => {
        state.twilioStore.twilioSession = {};
        state.twilioStore.callStatus = offline;
      });
      return;
    }

    const twilioDevice = new Device(response.data.token, {
      debug: false,
      enableRingingState: true,
      codecPreferences: ['opus', 'pcmu'],
      edge: 'sydney',
      logLevel: 'trace'
    });

    set((state) => {
      state.twilioStore.twilioToken = response.token;
      state.twilioStore.twilioDevice = twilioDevice;
      state.twilioStore.callStatus = ready;
      state.twilioStore.hasInitialised = true;
    });
  },

  makeCall(activeConnection) {
    set((state) => {
      state.twilioStore.showAddNoteForm = false;
      state.twilioStore.activeConnection = activeConnection;
      state.twilioStore.callStatus = ringing;
    });
  },

  disconnectCall() {
    const hasTwilioDevice = get().twilioStore.twilioDevice;

    if (hasTwilioDevice) {
      set((state) => {
        state.twilioStore.activeConnection = null;
      });
    }
  },

  setCallStatus(newCallStatus) {
    const currentCallStatus = get().twilioStore.callStatus;
    const wasConnected = currentCallStatus === connect;
    const nowNotConnect = newCallStatus === disconnect || newCallStatus === cancel;

    if (wasConnected && nowNotConnect) {
      set((state) => {
        state.twilioStore.showAddNoteForm = true;
      });
    }

    set((state) => {
      state.twilioStore.callStatus = newCallStatus;
    });
  },

  toggleShowDeviceOptions() {
    set((state) => {
      state.twilioStore.showDeviceOptions = !get().twilioStore.showDeviceOptions;
    });
  }
});

export { createTwilioStore };
