export const createStatusFromTerm = (data, filterKey) => {
  const statusList = data
    .filter((term) => term.termType === filterKey)
    .map((item) => {
      return {
        value: item.termKey,
        label: item.termLabel,
        callStatus: item.termAttributes?.callStatus,
        doNotRepeat: item.termAttributes?.doNotRepeat,
        termId: item.termId
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
  return statusList;
};
