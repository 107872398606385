import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import isEmpty from 'lodash.isempty';
import React, { useState, useEffect } from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';

import { useStore } from 'store';
import { StyledSelect, StyledSelectValue } from 'styles';

const ConnectedTeachingPeriodSelect = connectRefinementList(
  ({ items: teachingPeriods, refine, currentRefinement, label }) => {
    const [open, setOpen] = useState(false);
    const { getTeachingPeriodLabel, periods } = useStore((state) => state.sessionStore);

    const periodsList = periods === null ? [] : periods;

    const selectedTeachingPeriod = !isEmpty(currentRefinement) ? currentRefinement[0] : '';

    useEffect(() => {
      if (isEmpty(currentRefinement) && !isEmpty(teachingPeriods) && !isEmpty(periodsList)) {
        refine(periodsList[0].periodId);
      }
    }, [currentRefinement.length, teachingPeriods.length]);

    return (
      <>
        <InputLabel htmlFor="TeachingPeriodSelect">{label}</InputLabel>
        <StyledSelect
          fullWidth
          value={selectedTeachingPeriod}
          id="TeachingPeriodSelect"
          displayEmpty
          open={open}
          onChange={(e) => refine(e.target.value)}
          onClose={() => setOpen(false)}
          renderValue={(selected) => (
            <StyledSelectValue onClick={() => setOpen(true)} aria-label="TeachingPeriodSelect">
              {selected ? getTeachingPeriodLabel(selected) : 'Select week'}
            </StyledSelectValue>
          )}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
        >
          {periodsList.map((period) => (
            <MenuItem key={period.periodId} value={period.periodId}>
              <ListItemText primary={`TP ${period.tp} - Week ${period.week}`} />
            </MenuItem>
          ))}
        </StyledSelect>
      </>
    );
  }
);

ConnectedTeachingPeriodSelect.propTypes = {
  label: PropTypes.string
};

export default ConnectedTeachingPeriodSelect;
